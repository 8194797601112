import React from "react";
import "./Footer.css";
import cidb from "../../Images/cidb.png";
import bbbee from "../../Images/BBBEE.png";
const Footer = () => {
   return (
      <div className="Footer">
         <p>2024 | &copy; Thole Lifts (Pty) Ltd </p>
         <p>40 Chambers St, Booysens, Johannesburg South, 2091</p>
         <a href="https://www.zjml.cc/en/default.jsp" target="_blank">
            <p>Proud partner of Meilun Elevators</p>
         </a>
         <div className="badges">
            <div className="certification">
               <div className="text">
                  <img src={cidb} alt="none" />
                  <h3>8SI PE</h3>
               </div>
               <img src={bbbee} alt="none" />
            </div>
         </div>
      </div>
   );
};

export default Footer;
