import React, { useState, useEffect } from "react";
import "./App.css";
import HomePage from "./Pages/HomePage/HomePage";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Project from "./Pages/Project/Project";

function App() {
   return (
      <div className="App">
         <BrowserRouter>
            <Routes>
               <Route path="/" element={<HomePage />} />
               <Route path="/gallery" element={<Project />} />
               <Route path="/gallery/:name" element={<Project />} />
            </Routes>
         </BrowserRouter>
      </div>
   );
}

export default App;
