import React, { useState } from "react";
import "./Header.css";
import Logo from "../../Images/Logo1.png";
import { useNavigate } from "react-router-dom";

const Header = ({ scrollToSection, page, linked = false }) => {
   const [menu, setMenu] = useState(false);

   const navigate = useNavigate();

   const showMenu = () => {
      setMenu(!menu);
   };
   return (
      <div className="Header">
         <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className="burgerIcon"
            onClick={() => showMenu()}
         >
            <path d="M4 18L20 18" strokeWidth="2" strokeLinecap="round" />
            <path d="M4 12L20 12" strokeWidth="2" strokeLinecap="round" />
            <path d="M4 6L20 6" strokeWidth="2" strokeLinecap="round" />
         </svg>
         <div
            className="sideMenu"
            style={menu ? { display: "inline-block" } : { display: "none" }}
         >
            <div className="onClick">
               <div className="imgs">
                  <img src={Logo} alt="Logo" className="logo" />
                  <svg
                     viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                     className="close"
                     onClick={() => showMenu()}
                  >
                     <g id="Menu / Close_LG">
                        <path
                           id="Vector"
                           d="M21 21L12 12M12 12L3 3M12 12L21.0001 3M12 12L3 21.0001"
                           strokeWidth="2"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                        />
                     </g>
                  </svg>
               </div>
               <hr />
               <div className="menu2">
                  <ul>
                     <li
                        onClick={() => {
                           if (linked) {
                              navigate("/");
                           } else {
                              scrollToSection("home");
                           }
                           showMenu();
                        }}
                     >
                        <h2
                           style={
                              page === "home"
                                 ? {
                                      color: "var(--secondary-color)",
                                      borderBottom:
                                         "solid 1px var(--secondary-color)",
                                   }
                                 : null
                           }
                        >
                           Home
                        </h2>
                     </li>
                     <li
                        onClick={() => {
                           if (linked) {
                              navigate("/");
                           } else {
                              scrollToSection("about");
                           }
                           showMenu();
                        }}
                     >
                        <h2
                           style={
                              page === "about"
                                 ? {
                                      color: "var(--secondary-color)",
                                      borderBottom:
                                         "solid 1px var(--secondary-color)",
                                   }
                                 : null
                           }
                        >
                           About
                        </h2>
                     </li>
                     <li
                        onClick={() => {
                           if (linked) {
                              navigate("/");
                           } else {
                              scrollToSection("services");
                           }
                           showMenu();
                        }}
                     >
                        <h2
                           style={
                              page === "services"
                                 ? {
                                      color: "var(--secondary-color)",
                                      borderBottom:
                                         "solid 1px var(--secondary-color)",
                                   }
                                 : null
                           }
                        >
                           Services
                        </h2>
                     </li>
                     <li
                        onClick={() => {
                           if (linked) {
                              navigate("/");
                           } else {
                              scrollToSection("testimonials");
                           }
                           showMenu();
                        }}
                     >
                        <h2
                           style={
                              page === "testimonials"
                                 ? {
                                      color: "var(--secondary-color)",
                                      borderBottom:
                                         "solid 1px var(--secondary-color)",
                                   }
                                 : null
                           }
                        >
                           Our Customers
                        </h2>
                     </li>
                     <li
                        onClick={() => {
                           if (linked) {
                              navigate("/");
                           } else {
                              navigate("/gallery");
                           }
                           showMenu();
                        }}
                     >
                        <h2
                           style={
                              linked
                                 ? {
                                      color: "var(--secondary-color)",
                                      borderBottom:
                                         "solid 1px var(--secondary-color)",
                                   }
                                 : null
                           }
                        >
                           Gallery
                        </h2>
                     </li>

                     <li
                        onClick={() => {
                           if (linked) {
                              navigate("/");
                           } else {
                              scrollToSection("contact");
                           }
                           showMenu();
                        }}
                     >
                        <h2
                           style={
                              page === "contact"
                                 ? {
                                      color: "var(--secondary-color)",
                                      borderBottom:
                                         "solid 1px var(--secondary-color)",
                                   }
                                 : null
                           }
                        >
                           Contact Us
                        </h2>{" "}
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <img
            src={Logo}
            alt="Concrete construction logo"
            className="frontLogo"
         />
         <div className="menu">
            <ul>
               <li
                  onClick={() => {
                     if (linked) {
                        navigate("/");
                     } else {
                        scrollToSection("home");
                     }
                  }}
               >
                  <h2
                     style={
                        page === "home"
                           ? {
                                color: "var(--secondary-color)",
                                borderBottom:
                                   "solid 1px var(--secondary-color)",
                             }
                           : null
                     }
                  >
                     Home
                  </h2>
               </li>
               <li
                  onClick={() => {
                     if (linked) {
                        navigate("/");
                     } else {
                        scrollToSection("about");
                     }
                  }}
               >
                  <h2
                     style={
                        page === "about"
                           ? {
                                color: "var(--secondary-color)",
                                borderBottom:
                                   "solid 1px var(--secondary-color)",
                             }
                           : null
                     }
                  >
                     About
                  </h2>
               </li>
               <li
                  onClick={() => {
                     if (linked) {
                        navigate("/");
                     } else {
                        scrollToSection("services");
                     }
                  }}
               >
                  <h2
                     style={
                        page === "services"
                           ? {
                                color: "var(--secondary-color)",
                                borderBottom:
                                   "solid 1px var(--secondary-color)",
                             }
                           : null
                     }
                  >
                     Services
                  </h2>
               </li>
               <li
                  onClick={() => {
                     if (linked) {
                        navigate("/");
                     } else {
                        scrollToSection("testimonials");
                     }
                  }}
               >
                  <h2
                     style={
                        page === "testimonials"
                           ? {
                                color: "var(--secondary-color)",
                                borderBottom:
                                   "solid 1px var(--secondary-color)",
                             }
                           : null
                     }
                  >
                     Our Customers
                  </h2>
               </li>
               <li
                  onClick={() => {
                     if (linked) {
                        navigate("/");
                     } else {
                        navigate("/gallery");
                     }
                  }}
               >
                  <h2
                     style={
                        linked
                           ? {
                                color: "var(--secondary-color)",
                                borderBottom:
                                   "solid 1px var(--secondary-color)",
                             }
                           : null
                     }
                  >
                     Gallery
                  </h2>
               </li>

               <li
                  onClick={() => {
                     if (linked) {
                        navigate("/");
                     } else {
                        scrollToSection("contact");
                     }
                  }}
               >
                  <h2
                     style={
                        page === "contact"
                           ? {
                                color: "var(--secondary-color)",
                                borderBottom:
                                   "solid 1px var(--secondary-color)",
                             }
                           : null
                     }
                  >
                     Contact Us
                  </h2>{" "}
               </li>
            </ul>
         </div>
         <div className="phone">
            <p>Call Now</p>
            <p>
               <b>
                  <a href="tel:+27119421126">0119421126</a>
               </b>
            </p>
         </div>
      </div>
   );
};

export default Header;
