import React, { useState, useEffect } from "react";

import Header from "../../Components/Header/Header";
import Home from "../Home/Home";
import About from "../About/About";
import Services from "../Services/Services";
import Testimonials from "../Testimonials/Testimonials";
import Projects from "../Projects/Projects";
import Contact from "../Contact/Contact";
import Footer from "../../Components/Footer/Footer";

const HomePage = () => {
   const [hideHeader, setHideHeader] = useState(true);
   const [page, setPage] = useState(null);

   const scrollToSection = (id) => {
      const element = document.getElementById(id);
      if (element) {
         element.scrollIntoView({ behavior: "smooth" });
         console.log(page);
         setPage(id);
      }
   };

   useEffect(() => {
      const handleScroll = () => {
         const currentScrollPos = window.pageYOffset;
         let isScrolledDown = false;
         if (currentScrollPos > 0) {
            isScrolledDown = false;
         } else {
            isScrolledDown = true;
         }
         setHideHeader(isScrolledDown);
      };

      window.addEventListener("scroll", handleScroll);
   }, []);

   const sectionIds = [
      "home",
      "about",
      "services",
      "testimonials",
      "projects",
      "contact",
   ];

   useEffect(() => {
      const observer = new IntersectionObserver(
         (entries) => {
            entries.forEach((entry) => {
               if (entry.isIntersecting) {
                  setPage(entry.target.id);
               }
            });
         },
         {
            root: null, // Use the viewport as the root
            rootMargin: "0px",
            threshold: 0.5, // Trigger callback when 10% of the target is visible
         }
      );

      sectionIds.forEach((id) => {
         const section = document.getElementById(id);
         if (section) {
            observer.observe(section);
         }
      });

      return () => {
         sectionIds.forEach((id) => {
            const section = document.getElementById(id);
            if (section) {
               observer.unobserve(section);
            }
         });
      };
   }, []);

   return (
      <div>
         {" "}
         {!hideHeader && (
            <Header scrollToSection={scrollToSection} page={page} />
         )}
         <Home scrollToSection={scrollToSection} />
         <About />
         <Services />
         <Testimonials />
         <Contact />
         <Footer />
      </div>
   );
};

export default HomePage;
