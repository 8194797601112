import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "./Project.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

function importAll(r) {
   return r.keys().map(r);
}

const Project = () => {
   const { name } = useParams();

   const [images, setImages] = useState([]);
   const [imgNo, setImgNo] = useState(null);

   const nhbrc = importAll(
      require.context(
         "../../Images/Projects/NHBRC",
         false,
         /\.(png|jpe?g|svg)$/
      )
   );
   const ethekwini = importAll(
      require.context(
         "../../Images/Projects/Ethekwini",
         false,
         /\.(png|jpe?g|svg)$/
      )
   );
   const stateTheatre = importAll(
      require.context(
         "../../Images/Projects/StateTheatre",
         false,
         /\.(png|jpe?g|svg)$/
      )
   );
   useEffect(() => {
      if (name === "nhbrc") {
         setImages(nhbrc);
      } else if (name === "ethekwini") {
         setImages(ethekwini);
      } else if (name === "state theatre") {
         setImages(stateTheatre);
      } else {
         // setImages(nhbrc);
         setImages([...nhbrc, ...ethekwini, ...stateTheatre]);
         console.log(images);
      }
   }, []);
   return (
      <div className="Project">
         <Header linked={true} />
         <h2 className="heading">{name ? name : "Gallery"}</h2>
         <div className="imgss">
            {images.map((image, index) => (
               <img src={image} onClick={() => setImgNo(index)} />
            ))}
         </div>
         {imgNo !== null ? (
            <div className="viewImg">
               <img src={images[imgNo]} alt="" />
               <button onClick={() => setImgNo(null)}>Close</button>
            </div>
         ) : null}
         <Footer />
      </div>
   );
};

export default Project;
