import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./Testimonials.css";

import one from "../../Images/Customers/1.png";
import two from "../../Images/Customers/2.png";
import three from "../../Images/Customers/3.png";
import four from "../../Images/Customers/4.png";
import five from "../../Images/Customers/5.png";
import six from "../../Images/Customers/6.png";
import seven from "../../Images/Customers/7.png";
import eight from "../../Images/Customers/8.png";
import nine from "../../Images/Customers/9.png";
import ten from "../../Images/Customers/10.png";
import eleven from "../../Images/Customers/11.png";
import twelve from "../../Images/Customers/12.png";
import thirteen from "../../Images/Customers/13.png";
import fourteen from "../../Images/Customers/14.png";
import fivteen from "../../Images/Customers/15.png";
import sixteen from "../../Images/Customers/16.png";
import seventeen from "../../Images/Customers/17.png";
import eighteen from "../../Images/Customers/18.png";

const Testimonials = () => {
   const images = [
      { img: twelve, projectName: "" },
      { img: fourteen, projectName: "" },
      { img: one, projectName: "" },
      { img: two, projectName: "" },
      { img: nine, projectName: "Ethekwini" },
      { img: three, projectName: "NHBRC" },
      { img: fivteen, projectName: "State Theatre" },
      { img: four, projectName: "" },
      { img: five, projectName: "" },
      { img: six, projectName: "" },
      { img: seven, projectName: "" },
      { img: eight, projectName: "" },
      { img: ten, projectName: "" },
      { img: eleven, projectName: "" },
      { img: thirteen, projectName: "" },
      { img: sixteen, projectName: "" },
      { img: seventeen, projectName: "" },
      { img: eighteen, projectName: "" },
   ];

   const navigate = useNavigate();

   return (
      <div className="Testimonials" id="testimonials">
         <h2>OUR CUSTOMERS</h2>
         <div className="container">
            {images.map((image) => (
               <div
                  class="imgs"
                  onClick={() => {
                     navigate(`gallery/${image.projectName.toLowerCase()}`);
                  }}
               >
                  <img src={image.img} />
               </div>
            ))}
         </div>
      </div>
   );
};

export default Testimonials;
